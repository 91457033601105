import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import {
  Form,
  Col,
  Row,
  Image,
  Card
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminLoginForm.css';
import bt from '../../../components/commonStyle.css';
import arrow from './arrow.svg';
import { connect } from 'react-redux';
import Loader from '../../Common/Loader';
import Link from '../../Link/Link';

//Image
import globalIcon from '../../../../public/Icons/Language-icon.svg';
import gotoIcon from '../../../../public/Icons/gotoSite.svg';
import adminLoginImage from '../../../../public/static/loginBigImage.svg';
import { api, logoUploadDir } from '../../../config'
import HeaderModal from '../../HeaderModal/HeaderModal';
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';
import { formatLocale } from '../../../helpers/formatLocale';
import arrowLeft from '../../../../public/Icons/loginArrow.svg';

class AdminLoginForm extends Component {

  static defaultProps = {
    loading: false,
    siteName: ''
  };


  renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <label className={bt.labelText} >{label}</label>
        <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, loading, siteName, logo, openHeaderModal, currentLocale } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.loginMainBg}>
        <div className={s.loginBg} style={{ backgroundImage: `url(${adminLoginImage})` }}></div>
        <div>
          <div className={cx(s.header, 'loginHeaderRTL')}>
            <HeaderModal />
            <Link noLink
              onClick={() => openHeaderModal('languageModal')}
              className={s.flexLanguage}>

              <img src={globalIcon} className={s.globalIconCss} />

              <span className={cx(s.languageText, 'languageTextRTL')}>
                {formatLocale(currentLocale)}
              </span>
            </Link>
            <Link to={'/'} className={s.flexLanguage}> <img src={gotoIcon} className={s.globalIconCss} /> <span className={cx(s.languageText, 'languageTextRTL')}><FormattedMessage {...messages.goToMainSite} /></span></Link>
          </div>
          <div className={cx(s.container, 'loginInput')}>
            <Card className={cx(s.card, 'cardRTL')}>
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                width={128}
                height={'auto'}
                alt={siteName}
              />
              <div className={s.titleCss}><FormattedMessage {...messages.siteAdmin} /></div>
              <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                {error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                <Field
                  name="email"
                  type="text"
                  component={this.renderField}
                  label={formatMessage(messages.email)}
                  placeholder={formatMessage(messages.emailaddress)}
                  labelClass={bt.labelText}
                  fieldClass={cx(bt.formControlInput)}
                />
                <Field
                  name="password"
                  type="password"
                  component={this.renderField}
                  label={formatMessage(messages.password)}
                  placeholder={formatMessage(messages.password)}
                  labelClass={bt.labelText}
                  fieldClass={cx(bt.formControlInput)}
                />
                <Form.Group className={cx(s.loginButton, 'loadingBtnRTL')}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.login)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(bt.btnPrimary)}
                      disabled={submitting || loading}
                      isSuffix={true}
                      image={arrowLeft}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card>
          </div>
        </div>
        {/* <Row className={cx("d-flex", s.fullHeight)}>
          <Col md={6} lg={6} sm={6} xs={12} className={"d-none d-sm-block"}>
            <div className={s.welcomeContent}>
              <div>
                <h1>{siteName}</h1>
                <p><FormattedMessage {...messages.login} /></p>
              </div>
              <div className={cx(s.arrowIcon, 'arrowIconRTL')}><Image src={arrow} /></div>
            </div>
          </Col>
          <Col md={6} lg={6} sm={6} xs={12}>
           
          </Col>
        </Row> */}
      </div>
    )
  }
}

AdminLoginForm = reduxForm({
  form: 'AdminLoginForm', // a unique name for this form
  validate,
  onSubmit: submit
})(AdminLoginForm);

const mapState = (state) => ({
  loading: state.loader.AdminLogin,
  siteName: state.siteSettings.data.siteName,
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  currentLocale: state.intl.locale
});

const mapDispatch = {
  openHeaderModal,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AdminLoginForm)));

